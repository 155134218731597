<template>
  <div class="box-container">
    <div class="d-flex justify-between my-2 d-print-none">
      <div class="cursor-pointer" @click="$router.push({name: 'orders-market-place'})">
        <feather-icon icon="XIcon" size="32"></feather-icon>
      </div>
      <div class="d-flex">
        <button class="btn bg-pri bd-pri text-white ml-3" @click="print">{{ $t("In phiếu") }}</button>
      </div>
    </div>
    <div class id="report-printer">
      <div class="p-4 cs-border report-printer">
        <div class="font-style mb-3">
          <div class="flex justify-end fs-24">
            <div class="normal-case">
              <div>
                <span class="mr-1">{{ $t("Ký hiệu: ") }}</span>
                <span></span>
              </div>
              <div>
                <span class="mr-1">{{ $t("Số phiếu: ") }}</span>
                <span>{{ slip.number_code }}</span>
              </div>
            </div>
          </div>
          <div
            class="text-center uppercase my-3"
            style="font-size: 32px"
          >{{ $t("Phiếu thu dịch vụ y tế") }}</div>
        </div>

        <div>
          <div class="flex flex-col md:flex-row item-center gap-2"></div>
          <div class="flex item-center gap-2"></div>
        </div>
        <table class="mb-2 mt-2">
          <tr class="font-style">
            <th width="4%">{{ $t("STT") }}</th>
            <th width="35%">{{ $t("Tên dịch vụ") }}</th>
            <th width="6%">{{ $t("SL") }}</th>
            <th width="15%">{{ $t("Đơn giá") }}</th>
            <th width="15%">{{ $t("Thành tiền") }}</th>
          </tr>
          <tr class="table-row" v-for="(item, i) in order_items" :key="i + 'indicationService'">
            <td>{{ i + 1 }}</td>
            <td class="text-left">{{ item.data.product_name }}</td>
            <td>{{ item.data.qty }}</td>
            <td class="text-right">{{ getPrice(item.data) }}</td>

            <td
              class="text-right"
              style="text-align: right"
            >{{ appUtils.numberFormat(item.data.total_amount || 0) }}</td>
          </tr>

          <tr class="table-row">
            <td colspan="4" style="text-align: right" class="font-bold">{{ $t("Tổng tiền") }}</td>
            <td
              colspan="1"
              style="text-align: right"
              class="text-right font-bold"
            >{{ appUtils.numberFormat(slip.amount || 0) }}</td>
          </tr>
        </table>
        <div class="flex justify-between fs-24">
          <div class="text-black">
            <strong>{{ $t("Bằng chữ: ") }}</strong>
            <span class="cs-text inline-block">
              <p>{{ appUtils.numberToWords(slip.amount || 0) }}</p>
            </span>
          </div>
          <div class="text-black">
            <strong>{{ $t("Trạng thái: ") }}</strong>
            <span class="cs-text inline-block">
              <p>{{ paymentStatus }}</p>
            </span>
          </div>
        </div>
        <div class="box-footer mt-4">
          <div class="text-center text-black fs-24">
            <span style="font-size: 20px">{{ getHMDMYVN(slip.created_at) }}</span>
            <div class="font-style fs-24">{{ $t("Người lập") }}</div>
            <span class="italic">{{ $t("Ký, ghi rõ họ tên") }}</span>
            <div class="mt-16">
              <div class="sign-text">{{ slip.created_by && slip.created_by.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import moment from 'moment'
import appUtils from '@/utils/appUtils'
import 'element-ui/lib/theme-chalk/index.css'

export default {
  name: 'ReceiptDetail',
  props: {
    slip: Object,
    order_items: Array,
    isLoading: Boolean,
    payment: Object
  },
  data() {
    return {
      serviceDetail: {},
      indicationServices: [],
      appUtils,
      isShowWarning: false,
      messageError: ''
    }
  },
  watch: {},
  computed: {
    paymentStatus() {
      return this.payment?.status === 1 ? 'Chưa thanh toán' : 'Đã thanh toán'
    }
  },
  async mounted() {
    //
  },
  methods: {
    formatYear(date) {
      return window.moment(date).format('YYYY')
    },
    print() {
      const tempTitle = document.title
      document.title = `Danh sach dich vu_${moment().format('DDMMYYYY')}.pdf`
      window.print()
      document.title = tempTitle
    },

    getHMDMYVN(date) {
      const dateConvert = new Date(date)
      const d = dateConvert?.getDate()
      const m = dateConvert?.getMonth() + 1
      const y = dateConvert?.getFullYear()
      // const y = string.clone().format('YYYY')
      return 'Ngày ' + d + ' tháng ' + m + ' năm ' + y
    },
    getPrice(item) {
      return this.appUtils.numberFormat(item.price)
    }
  }
}
</script>
  
  <style scoped lang="scss">
.box-container {
  color: black;
  //   width: 1125px;
  padding: 0 20px;
  margin: auto;

  ::v-deep * {
    font-family: 'Times New Roman', 'Times', 'Tinos', serif !important;
  }
}

.box-footer {
  display: flex;
  justify-content: end;
  margin-right: 80px;
}

.font-style {
  color: black;
  font-weight: bold;
  font-size: 18px;
}

table,
th,
td {
  color: black;
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
  font-size: 24px;
  line-height: 32px;
}

table {
  width: 100%;
}

.table-row > td {
  padding: 0 5px;
}

.table-row,
.sign-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.sign-input {
  outline: 0;
  border-width: 0 0 1px;
  text-align: center;
}

.cs-note {
  display: none;
}

.cs-text {
  text-transform: lowercase;

  p::first-letter {
    text-transform: capitalize;
  }
}

.cs-border {
  border: 1px solid rgba(101, 109, 119, 0.16);
}

@media print {
  #BrowserPrintDefaults {
    display: none;
  }

  thead,
  tfoot {
    display: none !important;
  }

  @page {
    size: auto A4 landscape;
    margin: 0;
    margin-bottom: 10mm;
    margin-top: 10mm;
  }

  // .container,
  // .box-container {
  //   max-width: unset;
  //   width: 1150px;
  // }
  .sign-input {
    display: none;
  }

  .sign-text {
    display: block;
    font-weight: bold;
  }

  .word-break {
    word-break: break-all;
  }

  .marin-print {
    display: none;
  }

  .cs-note {
    display: block;
  }

  .cs-border {
    padding-top: 0px !important;

    border: none !important;
  }
}

::v-deep {
  .cs-input {
    input {
      background-color: #dae8fd !important;
      padding: 4px;
    }
  }
}

.cs-message {
  padding: 12px 20px !important;
  position: relative;
  border-radius: 8px;
  margin-bottom: 20px;

  .cs-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      font-size: 30px;
      padding: 0 !important;
      line-height: 1;
    }
  }

  .cs-button {
    background-color: #20419b;
    padding: 8px !important;
    margin-left: auto !important;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 40px;
  }
}

.bg-pri {
  background: #20419b;
}

.report-printer {
  font-family: Times New Roman, Times, Tinos, serif !important;
  //   font-size: 18px;
}
.text-black {
  color: #000;
}
.fs-24 {
  font-size: 24px;
}
</style>
  
<template>
  <b-overlay variant="light" :opacity="0.75" blur="2px" centered style="min-height: 10rem">
    <ReceiptDetail
      class="receipt-detail"
      :slip="slip"
      :payment="payment"
      :order_items="order_items"
      :isLoading="isLoading"
    />
  </b-overlay>
</template>

<script>
import ReceiptDetail from '@/views/OrdersMarketPlace/Detail/Components/ReceiptDetail.vue'
import store from '../../../../store'

export default {
  name: 'ModalDetailIndication',
  components: { ReceiptDetail },
  data() {
    return {
      orderDetail: {},
      isLoading: false
    }
  },
  mounted() {
    this.fetchDetailOrder()
  },
  computed: {
    slip() {
      return this.orderDetail?.slip || {}
    },
    order_items() {
      return this.orderDetail?.order_items || []
    },
    payment() {
      return this.orderDetail?.payment || {}
    }
  },
  methods: {
    async fetchDetailOrder() {
      this.isLoading = true
      const res = await store.dispatch('ecomOrder/fetchOrderById', {
        id: this.$route.params?.id
      })
      this.orderDetail = res?.data?.data
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.receipt-detail {
  .card-body {
    .cs-container {
      min-height: unset;
    }
  }
}
::v-deep#app {
  height: fit-content;
}
</style>